<template>
  <div class="flex-row align-items-center">
    <CRow class="justify-content-center headimg mb-5">
      <CCol md="12" class="p-0">
        <div
          style="
            background-image: url('img/Dashboard.png');
            background-position: 50% 50%;
            background-size: cover;
            height: 200px;
          "
        >
          <div class="middle-center text-center text-white">
            <h2 class="">
              <b
                >Formulir Permohonan
                <span class="text-success">{{ module }}</span></b
              >
            </h2>
            <h4>{{ kategori_module }}</h4>
          </div>
        </div>
      </CCol>
    </CRow>
    <div class="container">
      <CRow class="justify-content-center">
        <CCol md="12">
          <CCard class="top-radius-15">
            <CCardHeader class="top-radius-15 bg-success text-white">
              <h4 class="my-0 text-center"><b>Permohonan</b></h4>
            </CCardHeader>
            <CCardBody>
              <table class="w-100 table-tr-spacing">
                <tbody>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >No. Surat Permohonan
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="No. Surat Permohonan"
                        v-model="pr_nomor_surat"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Tanggal Surat <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="date"
                        class="form-control"
                        placeholder="Tanggal Surat"
                        v-model="pr_tanggal"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Perihal <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <textarea
                        rows="5"
                        class="form-control"
                        placeholder="Perihal"
                        readonly=""
                        v-model="pr_perihal"
                      ></textarea>
                    </td>
                  </tr>
                  <tr v-if="list_penanggung_jawab.length != 0">
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Penanggung Jawab
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <v-select
                        :options="list_penanggung_jawab"
                        label="nama_penanggung_jwb"
                        v-model="penanggung_jawab"
                        placeholder="Pilih Penanggung Jawab"
                      >
                        <template v-slot:option="value_list_penanggung_jawab">
                          {{ value_list_penanggung_jawab.nama_penanggung_jwb }}
                          -
                          {{
                            value_list_penanggung_jawab.jabatan_penanggung_jwb
                          }}
                        </template>
                        <template
                          #selected-option="value_list_penanggung_jawab"
                        >
                          {{ value_list_penanggung_jawab.nama_penanggung_jwb }}
                          -
                          {{
                            value_list_penanggung_jawab.jabatan_penanggung_jwb
                          }}
                        </template>
                      </v-select>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div
                        class="alert alert-info alert-sm"
                        v-if="list_penanggung_jawab.length != 0"
                      >
                        Nama penanggung jawab diambil dari oss, apabila nama
                        penanggung jawab tidak tersedia, mohon cek aplikasi
                        kemenkumham
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Data benih <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>Masukan data benih pada table dibawah</td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <table
                        class="table table-bordered table-sm"
                        id="table-benih"
                      >
                        <thead class="bg-sapphire">
                          <tr>
                            <th>Nama Latin</th>
                            <th>Varietas/Klon/Hibrida</th>
                            <th>Bentuk Benih</th>
                            <th>Jumlah</th>
                            <th>Satuan</th>
                            <th>Nilai <span>(Total Harga dalam USD)</span></th>
                            <th>Kode HS</th>
                            <td>
                              <div
                                class="btn btn-sm btn-success"
                                v-on:click="addRowBenih"
                              >
                                <i class="fa fa-plus"></i>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              value_rows_benih_raw, key_rows_benih_raw
                            ) in rows_benih_raw"
                            :key="key_rows_benih_raw"
                          >
                            <td>
                              <v-select
                                :options="list_tanaman"
                                label="t_nama"
                                v-model="value_rows_benih_raw.tanaman_id"
                                @input="
                                  changeJenisTanaman($event, key_rows_benih_raw)
                                "
                                :value="$store.myValue"
                                placeholder="Pilih Jenis Tanaman"
                              ></v-select>
                            </td>

                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_varietas"
                              />
                              <!-- <v-select :options="['Varietas','Klon','Hibrida']" placeholder="Pilih Varietas Klon Hibrida" v-model="value_rows_benih_raw.pb_varietas"></v-select>	 -->
                            </td>
                            <td>
                              <v-select
                                :options="list_bentuk_benih"
                                label="bb_nama"
                                v-model="value_rows_benih_raw.bentuk_benih_id"
                                placeholder="Pilih Bentuk Benih"
                              ></v-select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_jumlah"
                              />
                            </td>
                            <td>
                              <v-select
                                :options="list_satuan"
                                label="s_nama"
                                v-model="value_rows_benih_raw.satuan_id"
                                placeholder="Pilih Satuan"
                              ></v-select>
                            </td>
                            <td>
                              <input
                                type="number"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_nilai_total"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_rows_benih_raw.pb_kode_hs"
                              />
                            </td>
                            <td>
                              <div
                                class="btn btn-sm btn-danger"
                                v-on:click="
                                  deleteRow(
                                    key_rows_benih_raw,
                                    value_rows_benih_raw
                                  )
                                "
                              >
                                <i class="fa fa-minus"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <small>*) Pastikan data benih terisi lengkap</small>
                      <input type="hidden" id="benihcount" value="0" />
                    </td>
                  </tr>
                  <tr v-if="tujuan_pengeluaran_id == 3 && is_sayur_kentang">
                    <td colspan="3">
                      <table
                        class="table table-bordered table-sm"
                        id="table-benih"
                      >
                        <thead class="bg-sapphire">
                          <tr>
                            <th>Nama Kelompok Tani</th>
                            <th>NIK</th>
                            <th>NO. HP</th>
                            <th>Titik Koordinat</th>
                            <th>Keb Benih</th>
                            <th>Luas Lahan</th>
                            <td>
                              <div
                                class="btn btn-sm btn-success"
                                v-on:click="addRowKelompokTani"
                              >
                                <i class="fa fa-plus"></i>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              value_row_kelompok_tani, key_row_kelompok_tani
                            ) in row_kelompok_tani"
                            :key="key_row_kelompok_tani"
                          >
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="
                                  value_row_kelompok_tani.nama_kelompok_tani
                                "
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_row_kelompok_tani.nik"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_row_kelompok_tani.no_hp"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="
                                  value_row_kelompok_tani.titik_koordinat
                                "
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_row_kelompok_tani.kebun_benih"
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                class="form-control"
                                v-model="value_row_kelompok_tani.luas_lahan"
                              />
                            </td>
                            <td>
                              <div
                                class="btn btn-sm btn-danger"
                                v-on:click="
                                  deleteRowKelompokTani(
                                    key_row_kelompok_tani,
                                    value_row_kelompok_tani
                                  )
                                "
                              >
                                <i class="fa fa-minus"></i>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Perlakuan Fisik/Kimia
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Contoh : Fungisida, Bebas Tanah, Perendaman pestisida, Perendaman air hangat"
                        v-model="pr_perlakuan"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Negara Asal Benih
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <!-- <template v-slot:option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template>
												<template #selected-option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template> -->
                      <v-select
                        :filter="searchNegara"
                        :options="list_negara"
                        label="n_nama"
                        v-model="negara_asal"
                        placeholder="Pilih Negara Asal"
                      >
                      </v-select>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Nama Produsen <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nama Produsen"
                        v-model="pr_nama_produsen"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Negara Pengirim <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <!-- <template v-slot:option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template>
												<template #selected-option="value_list_negara">
													{{ value_list_negara.n_inisial }} - {{ value_list_negara.n_nama }}
												</template> -->
                      <v-select
                        :filter="searchNegara"
                        :options="list_negara"
                        label="n_nama"
                        v-model="negara_pengirim"
                        placeholder="Pilih Negara Tujuan"
                      >
                      </v-select>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Nama Pengirim <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Nama Pengirim"
                        v-model="pr_nama_pengirim"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Alamat Pengirim <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <textarea
                        rows="5"
                        class="form-control"
                        placeholder="Alamat Pengirim"
                        v-model="pr_alamat_pengirim"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Pekerjaan Pengirim
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Pekerjaan Pengirim"
                        v-model="pr_pekerjaan_pengirim"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Tempat Pengeluaran
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <v-select
                        :filter="searchPelabuhan"
                        :options="list_pelabuhan"
                        label="pl_nama"
                        v-model="pelabuhan"
                        placeholder="Pilih Tempat Pengeluaran"
                      >
                        <template v-slot:option="value_list_pelabuhan">
                          {{ value_list_pelabuhan.pl_code }} -
                          {{ value_list_pelabuhan.pl_nama }}
                        </template>
                        <template #selected-option="value_list_pelabuhan">
                          {{ value_list_pelabuhan.pl_code }} -
                          {{ value_list_pelabuhan.pl_nama }}
                        </template>
                      </v-select>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Tujuan Pengeluaran Benih
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <input
                        type="text"
                        class="form-control text-capitalize"
                        placeholder="Tujuan Pengeluaran Benih"
                        v-model="tujuan_pengeluaran"
                        readonly=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Keterangan Tujuan Pengeluaran Benih
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <!-- <input type="text" class="form-control text-capitalize" placeholder="Tujuan Pemasukan Benih" v-model="tujuan_pengeluaran" v-if="kategori_module == 'Pemasukan SDG Tanaman'"> -->
                      <textarea
                        rows="5"
                        class="form-control"
                        placeholder="Keterangan Tujuan Pengeluaran Benih"
                        v-model="pr_keterangan"
                      ></textarea>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3">
                      <div class="alert alert-info alert-sm">
                        Jika belum pernah melakukan realisasi, pilih
                        <strong>Permohonan Baru</strong>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th style="width: 250px">
                      <label for=""
                        ><b
                          >Nomor SIP Sebelumnya
                          <span class="text-danger">*</span></b
                        ></label
                      >
                    </th>
                    <td style="width: 15px">:</td>
                    <td>
                      <v-select
                        :options="['Permohonan Baru', 'Permohonan Lama']"
                        v-model="sipSelected"
                        @input="changeSistem($event)"
                        placeholder="Pilih Nomor SIP"
                      ></v-select>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table
                class="w-100 table-tr-spacing"
                v-if="sipSelected == 'Permohonan Lama'"
              >
                <template>
                  <tbody v-if="type_permohonan_loading">
                    <tr>
                      <td><i class="fa fa-refresh fa-spin"></i> Loading...</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <th style="width: 250px">
                        <label for=""
                          ><b
                            >No. SIP <span class="text-danger">*</span></b
                          ></label
                        >
                      </th>
                      <td style="width: 15px">:</td>
                      <td>
                        <v-select
                          :filter="searchSK"
                          :options="list_no_sk"
                          label="pr_nomor_sk"
                          v-model="sk_selected"
                          @input="changeSK($event)"
                          :value="$store.myValue"
                          placeholder="Pilih Nomor SK"
                        ></v-select>
                      </td>
                    </tr>

                    <tr v-if="afterChange">
                      <th style="width: 250px">
                        <label for=""><b>Data Benih</b></label>
                      </th>
                      <td style="width: 15px">:</td>
                      <td>Data Benih Realisasi</td>
                    </tr>
                    <tr v-if="afterChange">
                      <td colspan="3">
                        <table
                          class="table table-bordered table-sm w-100"
                          id="table-benih"
                        >
                          <thead class="bg-sapphire">
                            <tr>
                              <th style="width: 15px">No</th>
                              <th>Jenis Tanaman</th>
                              <th>Nama Varietas</th>
                              <th>Bentuk Benih</th>
                              <th>Jumlah</th>
                              <th>Jumlah Realisasi</th>
                              <th>Jumlah sisa</th>
                              <th>Satuan</th>
                              <th>Nilai (Total Harga dalam USD)</th>
                              <th>Kode HS</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(value_benih, index_benih) in data_benih"
                              :key="index_benih"
                            >
                              <td>{{ index_benih + 1 }}</td>
                              <td>
                                {{ value_benih.tanaman.t_nama }} -
                                {{ value_benih.tanaman.t_nama_latin }}
                              </td>

                              <td>{{ value_benih.pb_varietas }}</td>
                              <td>{{ value_benih.bentuk_benih.bb_nama }}</td>
                              <td>
                                {{ value_benih.pb_jumlah }}
                              </td>
                              <td>
                                {{ value_benih.jumlah_realisasi }}
                              </td>
                              <td>
                                {{
                                  value_benih.pb_jumlah -
                                  value_benih.jumlah_realisasi
                                }}
                              </td>
                              <td>{{ value_benih.satuan.s_nama }}</td>
                              <td>{{ value_benih.pb_nilai_total }}</td>
                              <td>{{ value_benih.pb_kode_hs }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>

              <table class="table table-sm table-bordered" v-if="afterChange">
                <thead class="bg-dark text-hite">
                  <tr class="text-center">
                    <th>Dokumen Realisasi</th>
                    <th style="width: 100px">File</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Dokumen Realisasi</td>
                    <td>
                      <template>
                        <div
                          v-if="file_realisasi.file"
                          target="_blank"
                          class="btn btn-sm btn-warning btn-hover-outline-warning text-nowrap"
                          v-on:click="getFile(file_realisasi.file)"
                        >
                          <i class="fa fa-eye"></i> Lihat
                        </div>
                        <div v-else>Data tidak tersedia</div>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol md="12">
          <CCard class="top-radius-15">
            <CCardHeader class="top-radius-15 bg-success text-white">
              <h4 class="my-0 text-center"><b>Dokumen Persyaratan</b></h4>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol
                  md="6"
                  v-for="(
                    value_list_document, index_list_document
                  ) in list_document"
                  :key="index_list_document"
                >
                  <CCard style="border-radius: 15px">
                    <CCardBody>
                      <b class="text-capitalize"
                        >{{ index_list_document + 1 }}.
                        {{ value_list_document.nama_dpp }}
                        <span
                          v-if="
                            value_list_document.nama_dpp !=
                            'Surat pernyataan jenis tanaman tidak ada dalam kepmentan 104'
                          "
                          class="text-danger"
                          >*</span
                        ></b
                      >
                      <!-- <template>
                        <a
                          :href="
                            'assets-download/Surat Permohonan Pemasukan ' +
                            module +
                            '.pdf'
                          "
                          target="_blank"
                          v-if="
                            value_list_document.nama_dpp == 'Surat Permohonan'
                          "
                        >
                          <span class="badge badge-info p-1 float-right"
                            ><i class="fa fa-download"></i> Contoh Surat
                            Permohonan</span
                          >
                        </a>
                        <a
                          href="assets-download/Format Proposal Pengajuan SIP Mentan SDG Tanaman.docx"
                          v-if="
                            value_list_document.nama_dpp ==
                            'Proposal Penelitian'
                          "
                        >
                          <span class="badge badge-info p-1 float-right"
                            ><i class="fa fa-download"></i> Format Proposal
                            Penelitian</span
                          >
                        </a>
                      </template>
                      <br /> -->
                      <input
                        type="file"
                        class="form-control mb-0"
                        :id="'files-doc-' + index_list_document"
                        v-on:change="inputFileDocument(index_list_document)"
                      />
                      <small
                        class="text-danger"
                        v-if="
                          value_list_document.nama_dpp == 'Laporan realisasi'
                        "
                        >* Permohonan baru wajib upload dokumen
                      </small>
                      <small class="float-right">*) Max File 2 MB</small>
                      <div v-if="value_list_document.text_dpp_info">
                        <small
                          >*) {{ value_list_document.text_dpp_info }}
                        </small>
                      </div>
                      <div
                        class="respond-input-file float-left"
                        :id="'docloading-' + index_list_document"
                      >
                        <div
                          target="_blank"
                          class="hover-pointer"
                          v-on:click="getFile(value_list_document.preview_link)"
                          v-if="value_list_document.preview_link"
                        >
                          <span class="badge badge-success p-1"
                            ><i class="fa fa-eye"></i> Lihat Dokumen</span
                          >
                        </div>
                      </div>
                      <br />
                      <input
                        type="hidden"
                        :id="'doc-ids-' + index_list_document"
                        :value="value_list_document.id"
                      />
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCol>
        <div class="col-4 text-center">
          <router-link
            to="/pemohon/dashboardpemohon"
            class="btn btn-warning btn-block text-white btn-hover-outline-warning btn-lg"
          >
            <i class="fa fa-chevron-left"></i>Kembali
          </router-link>
        </div>
        <div class="col-4 text-center">
          <div
            class="btn btn-info btn-block text-white btn-hover-outline-info btn-lg"
            v-on:click="submitPermohonan('draft')"
          >
            <i class="fa fa-bookmark-o"></i> Simpan Draft
          </div>
        </div>
        <div class="col-4 text-center" v-if="is_open">
          <div
            class="btn btn-success btn-block text-white btn-hover-outline-success btn-lg"
            v-on:click="modal_detail = true"
          >
            <i class="fa fa-paper-plane-o"></i> Ajukan Permohonan
          </div>
        </div>
      </CRow>
    </div>
    <template>
      <div>
        <CModal
          title="Preview Permohonan"
          color="btn-primary"
          :show.sync="modal_detail"
          size="xl"
        >
          <table class="w-100" border="0">
            <tr>
              <th style="width: 250px">No. Surat Permohonan</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_nomor_surat }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Tanggal Surat</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_tanggal }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Perihal</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_perihal }}</td>
            </tr>
            <tr v-if="penanggung_jawab">
              <th style="width: 250px">Penanggung Jawab Surat Keputusan</th>
              <td style="width: 15px">:</td>
              <td>
                {{ penanggung_jawab.nama_penanggung_jwb }} ({{
                  penanggung_jawab.jabatan_penanggung_jwb
                }})
              </td>
            </tr>
            <tr>
              <th style="width: 250px">Perlakuan Fisik/Kimia</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_perlakuan }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Negara Asal Benih</th>
              <td style="width: 15px">:</td>
              <td>{{ negara_asal.n_nama }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Nama Produsen</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_nama_produsen }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Negara Pengirim</th>
              <td style="width: 15px">:</td>
              <td>{{ negara_pengirim.n_nama }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Nama Pengirim</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_nama_pengirim }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Alamat Pengirim</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_alamat_pengirim }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Pekerjaan Pengirim</th>
              <td style="width: 15px">:</td>
              <td>{{ pr_pekerjaan_pengirim }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Tempat Pengeluaran</th>
              <td style="width: 15px">:</td>
              <td>{{ pelabuhan.pl_code }} - {{ pelabuhan.pl_nama }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Tujuan Pengeluaran Benih</th>
              <td style="width: 15px">:</td>
              <td>{{ tujuan_pengeluaran }}</td>
            </tr>
            <tr>
              <th style="width: 250px">Data benih</th>
              <td style="width: 15px">:</td>
              <td></td>
            </tr>
          </table>
          <table class="table table-bordered table-sm w-100" id="table-benih">
            <thead class="bg-sapphire">
              <tr>
                <th style="width: 15px">No</th>
                <th>Jenis Tanaman</th>
                <th>Nama Latin</th>
                <th>Nama Varietas</th>
                <th>Bentuk Benih</th>
                <th>Jumlah</th>
                <th>Satuan</th>
                <!-- <th>Nilai <span>(Total Harga dalam USD)</span></th> -->
                <th>Kode HS</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(value_benih, index_benih) in rows_benih_raw"
                :key="index_benih"
              >
                <td>{{ index_benih + 1 }}</td>
                <td>{{ value_benih.tanaman_id.t_nama }}</td>
                <td>{{ value_benih.pb_nama_latin }}</td>
                <td>{{ value_benih.pb_varietas }}</td>
                <td>
                  <div v-if="value_benih.bentuk_benih_id">
                    {{ value_benih.bentuk_benih_id.bb_nama }}
                  </div>
                </td>
                <td>{{ value_benih.pb_jumlah }}</td>
                <td>
                  <div v-if="value_benih.satuan_id">
                    {{ value_benih.satuan_id.s_nama }}
                  </div>
                </td>
                <!-- <td>{{ value_benih.pb_nilai_total }}</td> -->
                <td>{{ value_benih.pb_kode_hs }}</td>
              </tr>
            </tbody>
          </table>
          <table
            class="table table-bordered table-sm"
            v-if="tujuan_pengeluaran_id == 3"
          >
            <thead class="bg-sapphire">
              <tr>
                <th>Nama Kelompok Tani</th>
                <th>NIK</th>
                <th>NO. HP</th>
                <th>Titik Koordinat</th>
                <th>Keb Benih</th>
                <th>Luas Lahan</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  value_row_kelompok_tani, key_row_kelompok_tani
                ) in row_kelompok_tani"
                :key="key_row_kelompok_tani"
              >
                <td>
                  {{ value_row_kelompok_tani.nama_kelompok_tani }}
                </td>
                <td>
                  {{ value_row_kelompok_tani.nik }}
                </td>
                <td>
                  {{ value_row_kelompok_tani.no_hp }}
                </td>
                <td>
                  {{ value_row_kelompok_tani.titik_koordinat }}
                </td>
                <td>
                  {{ value_row_kelompok_tani.kebun_benih }}
                </td>
                <td>
                  {{ value_row_kelompok_tani.luas_lahan }}
                </td>
              </tr>
            </tbody>
          </table>
          <b>Disclaimer</b>
          <ol type="1">
            <li
              v-for="(
                value_rows_disclaimer, key_rows_disclaimer
              ) in disclaimer_permohonan"
              :key="key_rows_disclaimer"
            >
              {{ value_rows_disclaimer.disclaimer_label }}
            </li>
          </ol>
          <hr />
          <div class="form-group form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              v-model="setuju_permohonan"
            />
            <label class="form-check-label" for="exampleCheck1">{{
              checklist_permohonan
            }}</label>
          </div>
          <template #footer>
            <CButton @click="modal_detail = false" color="secondary"
              >Tutup</CButton
            >
            <CButton
              v-on:click="submitPermohonan('ajukan')"
              color="primary"
              v-if="setuju_permohonan"
              class="btn btn-success text-white btn-hover-outline-success"
              ><i class="fa fa-paper-plane-o"></i> Ajukan Permohonan</CButton
            >
          </template>
        </CModal>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "EditPermohonanPengeluaranPemohon",
  data() {
    return {
      rows_benih_raw: [],
      list_tanaman_kategori_sub: [],
      list_negara: [],
      list_tanaman: [],
      list_pelabuhan: [],
      list_document: [],
      permohonan_dokumen: [],
      list_bentuk_benih: [],
      list_satuan: [],
      negara_asal: "",
      negara_pengirim: "",
      pelabuhan: "",
      module: "",
      kategori_module: "",
      kategori_id_module: "",
      tujuan_pengeluaran: "",
      tujuan_pengeluaran_id: "",
      pr_cara_pengiriman: "",
      // Form input
      pr_nomor_surat: "",
      pr_tanggal: "",
      pr_perihal: "",
      tanaman_kategori_sub: "",
      pr_perlakuan: "",
      pr_nama_produsen: "",
      negara_pengirim_id: "",
      pr_nama_pengirim: "",
      pr_alamat_pengirim: "",
      pr_pekerjaan_pengirim: "",
      pr_keterangan: "-",
      session_data: JSON.parse(this.session),
      idpermohonan: this.$route.params.idpermohonan,
      is_open: false,
      sdg_komoditas: [],
      sdg_varietas_klon_hibrida: "",
      sdg_bentuk_benih: [],
      sdg_banyaknya: "",
      sdg_satuan: [],
      sdg_kode_hs: [],
      list_penanggung_jawab: [],
      penanggung_jawab: [],
      modal_detail: false,
      setuju_permohonan: false,
      row_kelompok_tani: [],
      is_sayur_kentang: false,
      disclaimer_permohonan: [],
      sipSelected: "",
      checklist_permohonan: "",
      dokumen_diproses: [],
      afterChange: false,
      beforeChange: false,
      type_permohonan_loading: false,
      list_no_sk: [],
      sk_selected: "",
      data_benih_realisasi: [],
      data_benih: [],
      file_realisasi: [],
      prs_nomor_sk: "",
    };
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    searchNegara(options, search) {
      const fuse = new Fuse(options, {
        keys: ["n_inisial", "n_nama"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    searchPelabuhan(options, search) {
      const fuse = new Fuse(options, {
        keys: ["pl_code", "pl_nama"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },
    getFile(files) {
      const normalizedUrl = this.normalizeUrl(this.apiLink + files);
      var win = window.open(normalizedUrl, "_blank");

      if (win) {
        //Browser has allowed it to be opened
        win.focus();
      } else {
        //Browser has blocked it
        alert("Please allow popups for this website");
      }
    },
    inputFileDocument(keys) {
      if ($("#files-doc-" + keys)[0].files[0]) {
        const file = $("#files-doc-" + keys)[0].files[0];
        const fileExtension = file.name.split(".").pop().toLowerCase();
        const maxSize = 2242880; // 2 MB

        if (fileExtension !== "pdf") {
          alert("Hanya file PDF yang diperbolehkan.");
          $("#files-doc-" + keys).val(""); // Reset input file jika bukan PDF
          return;
        }

        if (file.size < maxSize) {
          $("#docloading-" + keys).html(
            '<span class="badge badge-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var permohonan_dokumen_in = this.list_document[keys];
          var urlres = this.apiLink;
          var FormData = require("form-data");
          var data = new FormData();
          data.append("file", $("#files-doc-" + keys)[0].files[0]);
          data.append("id", $("#doc-ids-" + keys).val());
          var config = {
            method: "post",
            url: this.apiLink + "api/permohonan/uploads",
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
            data: data,
          };
          axios(config).then(function (response) {
            urlres += response.data.data.url;
            permohonan_dokumen_in.preview_link = response.data.data.file;
            $("#docloading-" + keys).html(
              '<span class="badge badge-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
                urlres +
                '" target="_blank" style="padding-left:10px;"><span class="badge badge-success p-1"><i class="fa fa-eye"></i> Lihat Dokumen</span></a>'
            );
          });
          // this.permohonan_dokumen[keys] = permohonan_dokumen_in;
          this.list_document[keys] = permohonan_dokumen_in;
        } else {
          alert("Max File 2 MB");
        }
      }
    },
    submitPermohonan(saveas) {
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon tunggu sebentar...",
        showConfirmButton: false,
      });
      let rows_benih = [];
      let required_form = new Promise(function (resolve, reject) {
        const fieldsToCheck = [
          "pr_nomor_surat",
          "pr_tanggal",
          "pr_perihal",
          "penanggung_jawab",
          "pr_perlakuan",
          "negara_asal",
          "pr_nama_produsen",
          "negara_pengirim",
          "pr_nama_pengirim",
          "pr_alamat_pengirim",
          "pr_pekerjaan_pengirim",
          "pelabuhan",
          "tujuan_pengeluaran",
          "pr_keterangan",
        ];

        let checkFieldEmpty = self.checkFormAllFilled(fieldsToCheck);
        if (!checkFieldEmpty && saveas != "draft") {
          Swal.fire({
            icon: "error",
            title: "Pastikan Data Terisi Lengkap",
          });
          resolve("stop");
        } else if (self.permohonan_dokumen.length != 0 && saveas != "draft") {
          /*
        else if (
          self.dokumen_diproses.length == "0" &&
          self.permohonan_dokumen.length == "0" &&
          saveas != "draft"
        ) {
          Swal.fire({
            icon: "error",
            title: "Anda belum mengunggah dokumen",
          });
          resolve("stop");
        }*/
          $.each(self.permohonan_dokumen, function (index, val) {
            if ((val.file == "" || val.file == null) && val.is_required) {
              Swal.fire({
                icon: "error",
                title: "Pastikan Data Dokumen Terunggah",
              });
              resolve("stop");
            }
          });
        } else if (self.rows_benih_raw.length === 0 && saveas != "draft") {
          Swal.fire({
            icon: "error",
            title: "Pastikan Data Benih Terisi",
          });
          resolve("stop");
        } else {
          $.each(self.rows_benih_raw, function (index, val) {
            if (
              val.tanaman_id == "" ||
              val.tanaman_id == null ||
              val.pb_nama_latin == "" ||
              val.pb_nama_latin == null ||
              val.pb_varietas == "" ||
              val.pb_varietas == null ||
              val.bentuk_benih_id == "" ||
              val.bentuk_benih_id == null ||
              val.pb_jumlah == "" ||
              val.pb_jumlah == null ||
              val.satuan_id == "" ||
              val.satuan_id == null ||
              val.pb_kode_hs == "" ||
              val.pb_kode_hs == null
            ) {
              Swal.fire({
                icon: "error",
                title: "Pastikan Data Benih Terisi",
              });
              resolve("stop");
            } else {
              rows_benih.push({
                tanaman_id: val.tanaman_id.id,
                pb_nama_umum: "-",
                pb_nama_latin: val.pb_nama_latin,
                pb_varietas: val.pb_varietas,
                pb_nama_induk: val.pb_nama_induk,
                bentuk_benih_id: val.bentuk_benih_id.id,
                pb_jumlah: val.pb_jumlah,
                satuan_id: val.satuan_id.id,
                pb_nilai_total: val.pb_nilai_total,
                pb_kode_hs: val.pb_kode_hs,
                is_aropt: true,
                pb_hibrida_inbrida_komposit: val.pb_hibrida_inbrida_komposit,
              });
            }
          });
        }

        resolve("next");
      });
      required_form.then(function (response) {
        if (response == "stop") {
          return false;
        }
        var FormData = require("form-data");
        var data = new FormData();
        // if (self.negara_pengirim == null) {
        // 	self.negara_pengirim = { "id": '0'};
        // }
        // if (self.pelabuhan == null) {
        // 	self.pelabuhan = { "id": '0'};
        // }
        data.append("module_id", self.kategori_id_module);
        data.append("pr_kategori", self.module);
        data.append("pr_perihal", self.pr_perihal);
        data.append("nib", self.session_data.nib);
        data.append("pr_nomor_surat", self.pr_nomor_surat);
        if (self.pr_tanggal == null) {
          self.pr_tanggal = self.formatDate(Date.now());
        }
        data.append("pr_tanggal", self.pr_tanggal);
        data.append("module_categories_id", self.kategori_id_module);
        data.append("pr_perlakuan", self.pr_perlakuan);
        if (self.negara_asal?.id) {
          data.append("negara_asal_id", self.negara_asal.id);
        }
        data.append("pr_nama_produsen", self.pr_nama_produsen);
        if (self.negara_pengirim?.id) {
          data.append("negara_pengirim_id", self.negara_pengirim.id);
        }
        data.append("pr_nama_pengirim", self.pr_nama_pengirim);
        data.append("pr_alamat_pengirim", self.pr_alamat_pengirim);
        data.append("pr_pekerjaan_pengirim", self.pr_pekerjaan_pengirim);
        if (self.pelabuhan?.id) {
          data.append("pelabuhan_id", self.pelabuhan.id);
        }
        data.append("tujuan_penggunaan_benih_id", self.tujuan_pengeluaran_id);
        data.append("pr_keterangan", self.pr_keterangan);
        data.append("pr_cara_pengiriman", self.pr_cara_pengiriman);
        data.append("status", saveas);
        data.append("permohonan_benih", JSON.stringify(rows_benih));
        data.append("id", self.idpermohonan);
        data.append("is_pemasukan", 0);
        data.append("perusahaan_id", self.session_data.perusahaan_id);
        if (self.tanaman_kategori_sub?.id) {
          data.append("tanaman_kategori_sub_id", self.tanaman_kategori_sub?.id);
        }
        if (self.sdg_komoditas?.id) {
          data.append("sdg_komoditas_id", self.sdg_komoditas?.id);
        }
        if (self.sdg_bentuk_benih?.id) {
          data.append("sdg_bentuk_benih_id", self.sdg_bentuk_benih?.id);
        }
        if (self.sdg_satuan?.id) {
          data.append("sdg_satuan_id", self.sdg_satuan?.id);
        }
        data.append(
          "sdg_varietas_klon_hibrida",
          self.sdg_varietas_klon_hibrida
        );
        data.append("sdg_banyaknya", self.sdg_banyaknya);
        data.append("sdg_kode_hs", self.sdg_kode_hs);
        data.append(
          "json_penanggung_jawab",
          JSON.stringify(self.penanggung_jawab)
        );
        data.append("kelompok_tani", JSON.stringify(self.row_kelompok_tani));
        var config = {
          method: "post",
          url: self.apiLink + "api/permohonan/update",
          //   timeout: 30000,
          headers: {
            Authorization: "Bearer " + self.access_token,
          },
          data: data,
        };
        axios(config)
          .then(function (response) {
            var res_ajukan = response.data;
            var res_ajukan_data = res_ajukan.data;
            if (res_ajukan.meta.code != "200") {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: res_ajukan.data.message,
              });
            } else {
              if (saveas == "draft") {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Anda akan diarahkan ke halaman dashboard",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  didOpen: () => {
                    timerInterval = setInterval(() => {
                      const content = Swal.getContent();
                      if (content) {
                        const b = content.querySelector("b");
                        if (b) {
                          b.textContent = Swal.getTimerLeft();
                        }
                      }
                    }, 100);
                  },
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    self.$router.push({ name: "DashboardPemohon" });
                  }
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Permohonan anda berhasil diajukan",
                  showConfirmButton: true,
                }).then((result) => {
                  if (result.isConfirmed) {
                    self.$router.push({ name: "DashboardPemohon" });
                  }
                });
                return false;
              }
            }
          })
          .catch((error) => {
            if (error) {
              if (error?.response) {
                var htmlerror = "";
                if (
                  typeof error.response.data.data.error === "string" ||
                  error.response.data.data.error instanceof String
                ) {
                  htmlerror = error.response.data.data.error;
                } else {
                  $.each(error.response.data.data.error, function (index, val) {
                    htmlerror += val[0] + "<br>";
                  });
                }
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  html: htmlerror,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Gagal",
                  text: "Gagal, Silahkan cek koneksi anda !",
                });
              }
            }
          });
      });
    },
    changeJenisTanaman(id_tanaman, key_row_tanaman) {
      // var tanaman = this.list_tanaman[id_tanaman.id];
      if (id_tanaman == null) {
        this.rows_benih_raw[key_row_tanaman].pb_nama_latin = "";
        return false;
      }
      // this.rows_benih_raw[key_row_tanaman].pb_nama_latin = id_tanaman.t_nama_latin;
      // var tanaman = this.list_tanaman.find(tanamans => {
      // 	if (tanamans.id === id_tanaman.id) {
      // 		return tanamans
      // 	}
      // });
      if (key_row_tanaman == 0) {
        if (id_tanaman.id == 136) {
          this.is_sayur_kentang = true;
        } else {
          this.is_sayur_kentang = false;
        }
      }
      this.rows_benih_raw[key_row_tanaman].pb_nama_latin =
        id_tanaman.t_nama_latin;
      this.rows_benih_raw[key_row_tanaman].is_select_hibrida =
        id_tanaman.is_select_hibrida;
      this.rows_benih_raw[key_row_tanaman].pb_hibrida_inbrida_komposit = "";
    },
    addRowBenih() {
      this.rows_benih_raw.push({
        tanaman_id: "",
        pb_nama_umum: "-",
        pb_nama_latin: "",
        pb_varietas: "",
        pb_nama_induk: "",
        bentuk_benih_id: "",
        pb_hibrida_inbrida_komposit: "",
        pb_jumlah: "",
        satuan_id: "",
        pb_nilai_total: "",
        pb_kode_hs: "",
        is_select_hibrida: false,
        is_aropt: true,
      });
    },
    deleteRow(index, rows_benih_raw_at) {
      var idx = this.rows_benih_raw.indexOf(rows_benih_raw_at);
      var tanaman = this.list_tanaman.find((tanamans) => {
        if (tanamans.id === rows_benih_raw_at.tanaman_id) {
          return tanamans;
        }
      });

      if (index == 0 && tanaman) {
        if (tanaman.id == 136) {
          this.is_sayur_kentang = false;
        } else {
          this.is_sayur_kentang = true;
        }
      }
      if (index > -1) {
        this.rows_benih_raw.splice(index, 1);
      }
    },
    addRowKelompokTani() {
      this.row_kelompok_tani.push({
        nama_kelompok_tani: "",
        nik: "",
        no_hp: "",
        titik_koordinat: "",
        kebun_benih: "",
        luas_lahan: "",
      });
    },
    deleteRowKelompokTani(index, row_kelompok_tani) {
      var idx = this.row_kelompok_tani.indexOf(row_kelompok_tani);
      if (idx > -1) {
        this.row_kelompok_tani.splice(idx, 1);
      }
    },
    //   fungsi urutkan asc
    urutkanASc(data) {
      return data.sort((a, b) => {
        return a.id - b.id;
      });
    },

    //   chek form empty field
    checkFormAllFilled(dataArray) {
      return dataArray.every((fieldName) => {
        const fieldValue = this[fieldName];
        return (
          fieldValue !== "" && fieldValue !== null && fieldValue !== undefined
        );
      });
    },

    changeSistem() {
      this.data_benih = [];
      this.id_permohonan_selected = "";
      this.prs_nomor_sk = "";
      this.sk_selected = "";
      this.type_permohonan_loading = true;
      this.beforeChange = true;
      axios
        .get(this.apiLink + "api/permohonan_realisasi/permohonan_module", {
          params: {
            nib: this.session_data.nib,
            module: "Benih Tanaman Pakan Ternak",
          },
          headers: {
            Authorization: "Bearer " + this.access_token,
          },
        })
        .then((response) => {
          this.type_permohonan_loading = false;
          var res_perohonan = response.data;
          var res_perohonan_data = res_perohonan.data;
          console.log("data permohonan realisasi : ", res_perohonan_data);
          if (res_perohonan.meta.code != "200") {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: res_perohonan.data.message,
            });
          } else {
            Swal.close();
            this.list_no_sk = res_perohonan_data.permohonan;
          }
        })
        .catch((error) => {
          if (error.response.data.data.error == "Permohonan tidak ditemukan") {
            this.sistem_baru_loading = false;
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.data.error,
            });
          }
        });
    },

    searchSK(options, search) {
      const fuse = new Fuse(options, {
        keys: ["pr_nomor_sk"],
        shouldSort: true,
      });
      return search.length
        ? fuse.search(search).map(({ item }) => item)
        : fuse.list;
    },

    changeSK(data_permohonan) {
      console.log("data permohonan diterima change SK : ", data_permohonan);
      this.beforeChange = false;
      this.afterChange = true;
      var raw_benih_realisasi = [];
      var raw_file_realisasi = [];

      if (data_permohonan && data_permohonan[0].permohonan_benih) {
        console.log(
          "Sebelum loop $.each, permohonan_benih: ",
          data_permohonan.permohonan_benih
        );
        $.each(data_permohonan[0].permohonan_benih, function (index, val) {
          raw_benih_realisasi[index] = {
            permohonan_benih_id: val.id,
            prsb_jumlah: val.pb_jumlah,
            prsb_jumlah_realisasi: val.jumlah_realisasi,
            prsb_jumlah_sisa: val.pb_jumlah_disestujui - val.jumlah_realisasi,
          };
        });
        this.data_benih_realisasi = raw_benih_realisasi;
        this.data_benih = data_permohonan[0].permohonan_benih;
        this.file_realisasi = data_permohonan[0].permohonan_dokumen[0];
        this.prs_nomor_sk = data_permohonan[0].pr_nomor_sk;

        console.log(
          "sesudah loop $.each, permohonan_benih: ",
          data_permohonan.permohonan_benih
        );
      }
    },

    normalizeUrl(url) {
      // Mencari semua instans dua slash atau lebih dan menggantinya dengan satu slash,
      // kecuali dua slash setelah protokol (http:, https:, file:, dan lain-lain).
      return url.replace(/([^:])\/{2,}/g, "$1/");
    },
  },
  mounted() {
    $(".select2").select2({
      placeholder: "-- Pilih --",
    });
  },
  created() {
    Swal.fire({
      title: '<i class="fa fa-refresh fa-spin"></i>',
      text: "Mohon tunggu sebentar...",
      showConfirmButton: false,
      allowOutsideClick: false,
    });
    this.changeSistem();
    let self = this;
    // Get Sub Jenis Tanaman
    axios
      .get(this.apiLink + "api/master/tanaman_kategori_sub", {
        params: {
          tanaman_kategori_id: 2,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_tanaman_kategori_sub = response.data;
        var res_tanaman_kategori_sub_data = res_tanaman_kategori_sub.data;
        if (res_tanaman_kategori_sub.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_tanaman_kategori_sub.data.message,
          });
        } else {
          this.list_tanaman_kategori_sub = res_tanaman_kategori_sub_data.master;
        }
      });
    axios
      .get(this.apiLink + "api/permohonan/detail", {
        params: {
          id: this.idpermohonan,
        },
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_permohonan = response.data;
        var res_permohonan_data = res_permohonan.data;

        if (res_permohonan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_permohonan.data.message,
          });
        } else {
          this.dokumen_diproses = res_permohonan_data.dokumen_proses;
          this.pr_nomor_surat = res_permohonan_data.permohonan.pr_nomor_surat;
          this.pr_tanggal = res_permohonan_data.permohonan.pr_tanggal;
          if (res_permohonan_data.permohonan.pr_perihal != "null")
            this.pr_perihal = res_permohonan_data.permohonan.pr_perihal;
          if (res_permohonan_data.permohonan.pr_perlakuan != "null")
            this.pr_perlakuan = res_permohonan_data.permohonan.pr_perlakuan;
          if (res_permohonan_data.permohonan.pr_nama_produsen != "null")
            this.pr_nama_produsen =
              res_permohonan_data.permohonan.pr_nama_produsen;
          if (res_permohonan_data.permohonan.pr_nama_pengirim != "null")
            this.pr_nama_pengirim =
              res_permohonan_data.permohonan.pr_nama_pengirim;
          if (res_permohonan_data.permohonan.pr_alamat_pengirim != "null")
            this.pr_alamat_pengirim =
              res_permohonan_data.permohonan.pr_alamat_pengirim;
          if (res_permohonan_data.permohonan.pr_pekerjaan_pengirim != "null")
            this.pr_pekerjaan_pengirim =
              res_permohonan_data.permohonan.pr_pekerjaan_pengirim;
          if (res_permohonan_data.negara_asal != null) {
            this.negara_asal = res_permohonan_data.negara_asal;
          }
          if (res_permohonan_data.permohonan.tanaman_kategori_sub != null) {
            this.tanaman_kategori_sub =
              res_permohonan_data.permohonan.tanaman_kategori_sub;
          }
          if (res_permohonan_data.negara_pengirim != null) {
            this.negara_pengirim = res_permohonan_data.negara_pengirim;
          }
          if (res_permohonan_data.pelabuhan != null) {
            this.pelabuhan = res_permohonan_data.pelabuhan;
          }
          if (res_permohonan_data?.tujuan_penggunaan_benih?.id) {
            this.tujuan_pengeluaran_id =
              res_permohonan_data.tujuan_penggunaan_benih.id;
          }
          this.tujuan_pengeluaran =
            res_permohonan_data.tujuan_penggunaan_benih.tpb_nama;
          this.kategori_id_module = res_permohonan_data.permohonan.modules_id;
          this.module = res_permohonan_data.permohonan.pr_kategori;
          this.kategori_module = res_permohonan_data.permohonan.pr_perihal;
          this.pr_cara_pengiriman =
            res_permohonan_data.permohonan.pr_cara_pengiriman;
          this.pr_keterangan = res_permohonan_data.permohonan.pr_keterangan;
          this.sdg_komoditas = res_permohonan_data.permohonan.sdg_komoditas;
          this.sdg_varietas_klon_hibrida =
            res_permohonan_data.permohonan.sdg_varietas_klon_hibrida;
          this.sdg_bentuk_benih =
            res_permohonan_data.permohonan.sdg_bentuk_benih;
          this.sdg_banyaknya = res_permohonan_data.permohonan.sdg_banyaknya;
          this.sdg_satuan = res_permohonan_data.permohonan.sdg_satuan;
          this.sdg_kode_hs = res_permohonan_data.permohonan.sdg_kode_hs;
          this.penanggung_jawab = JSON.parse(
            res_permohonan_data.permohonan.json_penanggung_jawab
          );
          this.row_kelompok_tani =
            res_permohonan_data.permohonan.permohonan_kelompok_tani;

          if (res_permohonan_data.doc_realisasi_ref) {
            this.sipSelected = "Permohonan Lama";
          } else {
            this.sipSelected = "Permohonan Baru";
          }

          if (this.list_no_sk.length != 0) {
            const selectedSK = this.list_no_sk.filter(
              (sk) =>
                sk.pr_nomor_sk === res_permohonan_data.permohonan.sip_reference
            );
            this.sk_selected = selectedSK;
            this.changeSK(this.sk_selected);
          }

          var benihdinamis = [];
          var sayur = false;
          $.each(
            res_permohonan_data.permohonan.permohonan_benih,
            function (index, val) {
              if (!sayur) {
                if (val.tanaman.id == 136) {
                  sayur = true;
                } else {
                  sayur = false;
                }
              }
              benihdinamis.push({
                tanaman_id: val.tanaman,
                pb_nama_umum: "-",
                pb_nama_latin: val.pb_nama_latin,
                pb_varietas: val.pb_varietas,
                pb_nama_induk: val.pb_nama_induk,
                bentuk_benih_id: val.bentuk_benih,
                pb_jumlah: val.pb_jumlah,
                satuan_id: val.satuan,
                pb_nilai_total: val.pb_nilai_total,
                pb_kode_hs: val.pb_kode_hs,
                is_aropt: val.is_aropt,
                pb_hibrida_inbrida_komposit: val?.pb_hibrida_inbrida_komposit,
                is_select_hibrida: val?.tanaman?.is_select_hibrida,
              });
            }
          );
          this.is_sayur_kentang = sayur;

          this.rows_benih_raw = benihdinamis;
          var clear_document = [];

          $.each(
            res_permohonan_data.permohonan.permohonan_dokumen,
            function (index, val) {
              clear_document.push({
                id: val.id,
                id_dpp:
                  val.dokumen_permohonan.dokumen_persyaratan_permohonan.id,
                nama_dpp:
                  val.dokumen_permohonan.dokumen_persyaratan_permohonan
                    .dpp_nama,
                template_dpp_link: "",
                text_dpp_info: "",
                status_verifikasi: val.permohonan_dokumen_verifikasi[0],
                preview_link: val.file,
              });
            }
          );
          // urutkan dokumen agar urutan form upload sama seperti pengajuan awal
          const urutkan_dokumen = this.urutkanASc(clear_document);
          this.list_document = urutkan_dokumen;
          Swal.close();
          // axios.get(this.apiLink + "api/master/dokumen_permohonan", {
          //              params: {
          //              	tujuan_penggunaan_benih_id: res_permohonan_data.tujuan_penggunaan_benih.id
          //              },
          //              headers: {
          //                  Authorization: "Bearer " + this.access_token,
          //              },
          //          })
          // .then((response) => {
          // 	var res_document = response.data;
          // 	var res_document_data = res_document.data;
          // 	if (res_document.meta.code != "200") {
          // 		Swal.fire({
          // 			icon: 'error',
          // 			title: 'Oops...',
          // 			text: res_document.data.message
          // 		});
          // 	}
          // 	else{
          // 		var raw_document = res_document_data.master;
          // 		var clear_document_inputed = [];
          // 		var clear_document = [];
          // 		$.each(raw_document, function(index, val) {
          // 			clear_document.push({
          // 			 	'id_dpp': val.dokumen_persyaratan_permohonan.id,
          // 			 	'nama_dpp': val.dokumen_persyaratan_permohonan.dpp_nama,
          // 			 	'template_dpp_link': '',
          // 			 	'text_dpp_info': '',
          // 			});
          // 			clear_document_inputed.push({
          // 				'dokumen_permohonan_id': val.id,
          // 				'file': ''
          // 			});
          // 		});
          // 		this.permohonan_dokumen = clear_document_inputed;
          // 		this.list_document = clear_document;
          // 	}
          // });
          axios
            .get(this.apiLink + "api/crud/konfigurasi", {
              params: {
                module_id: self.kategori_id_module,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              var res_konfigurasi = response.data;
              var res_konfigurasi_data = res_konfigurasi.data[0];
              var res_disclaimer_data = res_konfigurasi.data[1];
              if (res_konfigurasi.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_konfigurasi.data.message,
                });
              } else {
                if (res_disclaimer_data) {
                  self.disclaimer_permohonan = res_disclaimer_data;
                }
                var open_apli = res_konfigurasi_data.find((options) => {
                  if (options.id === 1) {
                    return options;
                  }
                });
                if (open_apli.k_aksi != "open") {
                  self.is_open = true;
                }

                var checklist_permohonan = res_konfigurasi_data.find(
                  (options) => {
                    if (options.id === 5) {
                      return options;
                    }
                  }
                );
                if (checklist_permohonan.k_aksi) {
                  self.checklist_permohonan = checklist_permohonan.k_aksi;
                }
              }
            });
          // Get Master Tanaman
          axios
            .get(this.apiLink + "api/master/tanaman", {
              params: {
                tanaman_kategori_id:
                  res_permohonan_data.tujuan_penggunaan_benih
                    .tanaman_kategori_id,
              },
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            })
            .then((response) => {
              var res_tanaman = response.data;
              var res_tanaman_data = res_tanaman.data;
              if (res_tanaman.meta.code != "200") {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: res_tanaman.data.message,
                });
              } else {
                var raw_tanaman = res_tanaman_data.master;
                var clear_tanaman = [];
                var nama_module = this.module;
                $.each(raw_tanaman, function (index_kategori, val_kategori) {
                  $.each(
                    val_kategori.tanaman_kategori_sub,
                    function (index_sub_kategori, val_sub_kategori) {
                      var sub_kategori = val_sub_kategori.tanaman_kategori_nama;
                      if (nama_module != "Sumber Daya Genetik") {
                        if (
                          sub_kategori.toLowerCase() ==
                          nama_module.toLowerCase()
                        ) {
                          $.each(
                            val_sub_kategori.tanaman,
                            function (index_tanaman, val_tanaman) {
                              clear_tanaman[val_tanaman.id] =
                                val_sub_kategori.tanaman[index_tanaman];
                            }
                          );
                        }
                      } else {
                        $.each(
                          val_sub_kategori.tanaman,
                          function (index_tanaman, val_tanaman) {
                            clear_tanaman[val_tanaman.id] =
                              val_sub_kategori.tanaman[index_tanaman];
                          }
                        );
                      }
                    }
                  );
                });
                var clear_tanaman = clear_tanaman.filter(function (el) {
                  return el != null;
                });
                this.list_tanaman = clear_tanaman;
                Swal.close();
              }
            });
        }
      });
    // Get Master Negara
    axios
      .get(this.apiLink + "api/master/negara", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_negara = response.data;
        var res_negara_data = res_negara.data;
        if (res_negara.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_negara.data.message,
          });
        } else {
          this.list_negara = res_negara_data.master;
        }
      });
    // Get Master Pelabuhan
    axios
      .get(this.apiLink + "api/master/pelabuhan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_pelabuhan = response.data;
        var res_pelabuhan_data = res_pelabuhan.data;
        if (res_pelabuhan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_pelabuhan.data.message,
          });
        } else {
          this.list_pelabuhan = res_pelabuhan_data.master;
        }
      });
    axios
      .get(this.apiLink + "api/master/bentuk_benih", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_bentuk_benih = response.data;
        var res_bentuk_benih_data = res_bentuk_benih.data;
        if (res_bentuk_benih.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_bentuk_benih.data.message,
          });
        } else {
          this.list_bentuk_benih = res_bentuk_benih_data.master;
        }
      });
    axios
      .get(this.apiLink + "api/master/satuan", {
        params: {},
        headers: {
          Authorization: "Bearer " + this.access_token,
        },
      })
      .then((response) => {
        var res_satuan = response.data;
        var res_satuan_data = res_satuan.data;
        if (res_satuan.meta.code != "200") {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res_satuan.data.message,
          });
        } else {
          this.list_satuan = res_satuan_data.master;
        }
      });

    var json_perusahaan = JSON.parse(this.session_data.perusahaan.p_json);
    if (json_perusahaan) {
      this.list_penanggung_jawab =
        json_perusahaan.responinqueryNIB.dataNIB.penanggung_jwb;
    }
  },
};
</script>
